import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 552 452" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >


<path fill="#03312E" opacity="1.000000" stroke="none" 
	d="
M46.000000,1.000000 
	C203.357422,1.000000 360.714844,1.000000 518.638245,1.330344 
	C521.609863,2.995928 524.163757,4.121662 526.395813,5.702068 
	C536.901733,13.140759 543.856262,22.814119 544.571167,36.278275 
	C544.135864,38.335590 543.993103,40.159794 543.992371,41.984055 
	C543.979004,74.941528 543.978271,107.899002 543.983948,140.856476 
	C543.999573,232.237808 544.019348,323.619141 544.128540,415.305176 
	C543.997498,426.468231 539.348938,435.158356 531.209534,442.129303 
	C525.379456,447.122467 518.284546,449.201263 510.907471,450.657166 
	C510.191742,450.332123 509.576294,450.201538 508.495575,450.040222 
	C357.038635,450.011963 206.046997,450.014465 54.750839,450.080322 
	C37.092079,449.019012 21.057421,434.715271 20.997692,415.496948 
	C21.010897,288.353149 20.998669,161.675583 21.029099,34.599297 
	C23.394152,19.432526 30.554760,8.317646 44.872879,2.553508 
	C45.375580,2.351130 45.629910,1.531788 46.000000,1.000000 
M312.500183,341.260742 
	C259.427979,341.260742 206.355789,341.260742 152.688446,341.260742 
	C152.688446,254.415390 152.688446,168.373459 152.688446,82.415390 
	C142.172943,82.415390 132.260452,82.415390 122.405960,82.415390 
	C122.405960,179.159500 122.405960,275.537659 122.405960,371.883484 
	C229.071320,371.883484 335.329071,371.883484 441.858582,371.883484 
	C441.959320,370.835846 442.085419,370.041290 442.105011,369.244080 
	C442.391357,357.599365 442.852448,345.954315 442.852051,334.309326 
	C442.851868,329.190796 442.037140,324.052734 441.283783,318.969238 
	C441.119965,317.863861 439.654938,316.105164 438.757050,316.085083 
	C429.304138,315.873718 419.844727,315.954346 410.182068,315.954346 
	C410.182068,324.594055 410.182068,332.764954 410.182068,341.261169 
	C377.448883,341.261169 345.474548,341.261169 312.500183,341.260742 
M205.268723,242.195602 
	C199.738953,234.574554 194.209198,226.953491 188.679428,219.332443 
	C188.195999,219.673477 187.712570,220.014511 187.229126,220.355530 
	C197.837585,251.090378 208.446045,281.825226 219.192123,312.958801 
	C278.005249,282.101532 336.321930,251.504745 394.638580,220.907959 
	C392.564270,220.353729 390.888672,220.815353 389.248077,221.378937 
	C337.607208,239.119812 285.955444,256.829498 234.363190,274.710754 
	C230.854233,275.926941 229.115738,275.267792 227.069962,272.339417 
	C220.106323,262.371460 212.852676,252.606094 205.268723,242.195602 
M191.651642,78.276405 
	C190.656479,78.276299 189.645798,78.389977 188.668762,78.256821 
	C183.900528,77.607025 182.012650,79.574463 182.401184,84.399551 
	C182.719666,88.354607 182.527130,92.356773 182.449005,96.335846 
	C182.391846,99.247452 183.612015,100.558258 186.598114,100.524109 
	C194.226669,100.436859 201.857529,100.435860 209.486115,100.521347 
	C212.425720,100.554276 213.732193,99.385384 213.662659,96.398026 
	C213.558502,91.922279 213.515167,87.437599 213.682098,82.965530 
	C213.809235,79.559448 212.476715,78.143623 209.031479,78.238098 
	C203.562210,78.388062 198.085785,78.277016 191.651642,78.276405 
M245.435364,85.479591 
	C244.488358,90.539810 243.652222,95.624329 242.550812,100.650711 
	C241.935226,103.460045 242.493164,105.118401 245.550781,105.662094 
	C253.234863,107.028450 260.904572,108.477425 268.570496,109.943230 
	C271.205444,110.447044 272.653076,109.595772 273.087494,106.824066 
	C273.755768,102.560600 274.362091,98.257843 275.483215,94.103600 
	C276.524414,90.245560 275.383942,88.603119 271.507996,87.983536 
	C264.624420,86.883179 257.809540,85.355423 250.928833,84.233757 
	C249.307022,83.969368 247.537827,84.608963 245.435364,85.479591 
M315.445465,126.953117 
	C319.583405,129.555527 322.257263,128.959015 323.756378,123.832664 
	C324.911987,119.880928 327.117340,116.237854 328.297272,112.290543 
	C328.666138,111.056473 327.624176,108.399307 326.532043,107.892059 
	C319.323181,104.543900 311.899017,101.663277 304.601440,98.500107 
	C302.048889,97.393684 300.632019,97.956749 299.626617,100.561043 
	C297.956299,104.887718 296.260864,109.226730 294.199921,113.373642 
	C292.531342,116.731041 293.766449,118.048515 296.841400,119.230278 
	C302.870605,121.547447 308.780609,124.174759 315.445465,126.953117 
M367.619629,158.187134 
	C371.071594,154.349487 374.475555,150.467087 377.994476,146.691879 
	C379.936188,144.608749 380.246124,142.848145 377.887421,140.813812 
	C371.959869,135.701431 366.228943,130.353653 360.154297,125.426834 
	C359.168671,124.627464 356.174469,124.815582 355.229797,125.719238 
	C351.631744,129.161087 348.382324,132.994568 345.266571,136.892685 
	C344.558929,137.778061 344.277069,140.207962 344.869537,140.744064 
	C351.643616,146.873550 358.617615,152.782684 365.580933,158.700317 
	C365.833801,158.915222 366.525818,158.613434 367.619629,158.187134 
M389.359253,172.889542 
	C385.471832,175.456482 385.663635,177.907242 388.980865,181.095612 
	C393.396881,185.340088 397.405426,190.013672 401.513245,194.571198 
	C403.269531,196.519775 404.891571,197.002090 406.962860,195.010162 
	C410.070038,192.022018 413.233490,189.086960 416.474945,186.245651 
	C418.677399,184.315033 418.241486,182.698105 416.404572,180.836487 
	C413.959656,178.358810 411.649872,175.747559 409.287994,173.188187 
	C399.941040,163.059586 399.941895,163.058792 389.359253,172.889542 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M45.531342,1.000000 
	C45.629910,1.531788 45.375580,2.351130 44.872879,2.553508 
	C30.554760,8.317646 23.394152,19.432526 20.630381,34.782700 
	C20.123861,36.189964 20.001694,37.015091 20.001646,37.840225 
	C19.994232,162.550568 19.992624,287.260895 20.037100,411.971252 
	C20.037575,413.302002 20.647076,414.632538 20.972265,415.963196 
	C21.057421,434.715271 37.092079,449.019012 54.934319,450.474609 
	C71.578461,450.892944 87.734612,450.980347 104.079582,451.443634 
	C104.178932,452.213043 104.089470,452.606506 104.000000,453.000000 
	C69.691711,453.000000 35.383430,453.000000 1.047364,453.000000 
	C1.047364,302.333344 1.047364,151.666656 1.047364,1.000000 
	C15.750713,1.000000 30.406698,1.000000 45.531342,1.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M544.929871,36.045185 
	C543.856262,22.814119 536.901733,13.140759 526.395813,5.702068 
	C524.163757,4.121662 521.609863,2.995928 519.102112,1.330344 
	C530.324707,1.000000 541.649353,1.000000 552.980591,0.999999 
	C552.987061,151.542938 552.987061,302.085876 552.987061,453.000000 
	C524.313477,453.000000 495.625397,453.000000 466.114136,452.769714 
	C465.563721,452.048737 465.836456,451.558105 466.570740,451.039948 
	C481.010406,450.982208 494.988617,450.951996 509.307220,450.924622 
	C510.101013,450.902161 510.554352,450.876862 511.007721,450.851593 
	C518.284546,449.201263 525.379456,447.122467 531.209534,442.129303 
	C539.348938,435.158356 543.997498,426.468231 544.517517,415.107788 
	C545.190796,396.334778 545.915833,378.063293 545.884094,359.793152 
	C545.697510,252.208420 545.334656,144.623978 545.026978,37.039455 
	C545.026062,36.707939 544.963562,36.376606 544.929871,36.045185 
z"/>
<path fill="#76777A" opacity="1.000000" stroke="none" 
	d="
M466.109222,451.067474 
	C465.836456,451.558105 465.563721,452.048737 465.645508,452.769714 
	C345.644653,453.000000 225.289307,453.000000 104.466988,453.000000 
	C104.089470,452.606506 104.178932,452.213043 104.554466,451.429810 
	C120.497124,451.026642 136.153717,450.999207 151.810303,451.001923 
	C256.576599,451.020172 361.342926,451.044861 466.109222,451.067474 
z"/>
<path fill="#67ECAA" opacity="1.000000" stroke="none" 
	d="
M313.000183,341.260956 
	C345.474548,341.261169 377.448883,341.261169 410.182068,341.261169 
	C410.182068,332.764954 410.182068,324.594055 410.182068,315.954346 
	C419.844727,315.954346 429.304138,315.873718 438.757050,316.085083 
	C439.654938,316.105164 441.119965,317.863861 441.283783,318.969238 
	C442.037140,324.052734 442.851868,329.190796 442.852051,334.309326 
	C442.852448,345.954315 442.391357,357.599365 442.105011,369.244080 
	C442.085419,370.041290 441.959320,370.835846 441.858582,371.883484 
	C335.329071,371.883484 229.071320,371.883484 122.405960,371.883484 
	C122.405960,275.537659 122.405960,179.159500 122.405960,82.415390 
	C132.260452,82.415390 142.172943,82.415390 152.688446,82.415390 
	C152.688446,168.373459 152.688446,254.415390 152.688446,341.260742 
	C206.355789,341.260742 259.427979,341.260742 313.000183,341.260956 
z"/>
<path fill="#67EAA8" opacity="1.000000" stroke="none" 
	d="
M205.489258,242.479492 
	C212.852676,252.606094 220.106323,262.371460 227.069962,272.339417 
	C229.115738,275.267792 230.854233,275.926941 234.363190,274.710754 
	C285.955444,256.829498 337.607208,239.119812 389.248077,221.378937 
	C390.888672,220.815353 392.564270,220.353729 394.638580,220.907959 
	C336.321930,251.504745 278.005249,282.101532 219.192123,312.958801 
	C208.446045,281.825226 197.837585,251.090378 187.229126,220.355530 
	C187.712570,220.014511 188.195999,219.673477 188.679428,219.332443 
	C194.209198,226.953491 199.738953,234.574554 205.489258,242.479492 
z"/>
<path fill="#66E7A5" opacity="1.000000" stroke="none" 
	d="
M192.132019,78.276398 
	C198.085785,78.277016 203.562210,78.388062 209.031479,78.238098 
	C212.476715,78.143623 213.809235,79.559448 213.682098,82.965530 
	C213.515167,87.437599 213.558502,91.922279 213.662659,96.398026 
	C213.732193,99.385384 212.425720,100.554276 209.486115,100.521347 
	C201.857529,100.435860 194.226669,100.436859 186.598114,100.524109 
	C183.612015,100.558258 182.391846,99.247452 182.449005,96.335846 
	C182.527130,92.356773 182.719666,88.354607 182.401184,84.399551 
	C182.012650,79.574463 183.900528,77.607025 188.668762,78.256821 
	C189.645798,78.389977 190.656479,78.276299 192.132019,78.276398 
z"/>
<path fill="#67EAA8" opacity="1.000000" stroke="none" 
	d="
M245.635895,85.156143 
	C247.537827,84.608963 249.307022,83.969368 250.928833,84.233757 
	C257.809540,85.355423 264.624420,86.883179 271.507996,87.983536 
	C275.383942,88.603119 276.524414,90.245560 275.483215,94.103600 
	C274.362091,98.257843 273.755768,102.560600 273.087494,106.824066 
	C272.653076,109.595772 271.205444,110.447044 268.570496,109.943230 
	C260.904572,108.477425 253.234863,107.028450 245.550781,105.662094 
	C242.493164,105.118401 241.935226,103.460045 242.550812,100.650711 
	C243.652222,95.624329 244.488358,90.539810 245.635895,85.156143 
z"/>
<path fill="#67EAA8" opacity="1.000000" stroke="none" 
	d="
M315.092712,126.813416 
	C308.780609,124.174759 302.870605,121.547447 296.841400,119.230278 
	C293.766449,118.048515 292.531342,116.731041 294.199921,113.373642 
	C296.260864,109.226730 297.956299,104.887718 299.626617,100.561043 
	C300.632019,97.956749 302.048889,97.393684 304.601440,98.500107 
	C311.899017,101.663277 319.323181,104.543900 326.532043,107.892059 
	C327.624176,108.399307 328.666138,111.056473 328.297272,112.290543 
	C327.117340,116.237854 324.911987,119.880928 323.756378,123.832664 
	C322.257263,128.959015 319.583405,129.555527 315.092712,126.813416 
z"/>
<path fill="#67EAA8" opacity="1.000000" stroke="none" 
	d="
M367.316406,158.369781 
	C366.525818,158.613434 365.833801,158.915222 365.580933,158.700317 
	C358.617615,152.782684 351.643616,146.873550 344.869537,140.744064 
	C344.277069,140.207962 344.558929,137.778061 345.266571,136.892685 
	C348.382324,132.994568 351.631744,129.161087 355.229797,125.719238 
	C356.174469,124.815582 359.168671,124.627464 360.154297,125.426834 
	C366.228943,130.353653 371.959869,135.701431 377.887421,140.813812 
	C380.246124,142.848145 379.936188,144.608749 377.994476,146.691879 
	C374.475555,150.467087 371.071594,154.349487 367.316406,158.369781 
z"/>
<path fill="#7A8987" opacity="1.000000" stroke="none" 
	d="
M544.571167,36.278275 
	C544.963562,36.376606 545.026062,36.707939 545.026978,37.039455 
	C545.334656,144.623978 545.697510,252.208420 545.884094,359.793152 
	C545.915833,378.063293 545.190796,396.334778 544.426392,414.803040 
	C544.019348,323.619141 543.999573,232.237808 543.983948,140.856476 
	C543.978271,107.899002 543.979004,74.941528 543.992371,41.984055 
	C543.993103,40.159794 544.135864,38.335590 544.571167,36.278275 
z"/>
<path fill="#67EAA8" opacity="1.000000" stroke="none" 
	d="
M389.617981,172.647858 
	C399.941895,163.058792 399.941040,163.059586 409.287994,173.188187 
	C411.649872,175.747559 413.959656,178.358810 416.404572,180.836487 
	C418.241486,182.698105 418.677399,184.315033 416.474945,186.245651 
	C413.233490,189.086960 410.070038,192.022018 406.962860,195.010162 
	C404.891571,197.002090 403.269531,196.519775 401.513245,194.571198 
	C397.405426,190.013672 393.396881,185.340088 388.980865,181.095612 
	C385.663635,177.907242 385.471832,175.456482 389.617981,172.647858 
z"/>
<path fill="#76928D" opacity="1.000000" stroke="none" 
	d="
M466.570740,451.039948 
	C361.342926,451.044861 256.576599,451.020172 151.810303,451.001923 
	C136.153717,450.999207 120.497124,451.026642 104.365654,451.053894 
	C87.734612,450.980347 71.578461,450.892944 55.238819,450.411285 
	C206.046997,450.014465 357.038635,450.011963 508.524994,450.252716 
	C509.019684,450.496002 508.966797,450.921783 508.966797,450.921783 
	C494.988617,450.951996 481.010406,450.982208 466.570740,451.039948 
z"/>
<path fill="#758B8B" opacity="1.000000" stroke="none" 
	d="
M20.997696,415.496948 
	C20.647076,414.632538 20.037575,413.302002 20.037100,411.971252 
	C19.992624,287.260895 19.994232,162.550568 20.001646,37.840225 
	C20.001694,37.015091 20.123861,36.189964 20.587723,35.181435 
	C20.998669,161.675583 21.010897,288.353149 20.997696,415.496948 
z"/>
<path fill="#002C24" opacity="1.000000" stroke="none" 
	d="
M509.307220,450.924622 
	C508.966797,450.921783 509.019684,450.496002 508.990265,450.283508 
	C509.576294,450.201538 510.191742,450.332123 510.907471,450.657166 
	C510.554352,450.876862 510.101013,450.902161 509.307220,450.924622 
z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
